<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="text-center p-3" style="background-color: rgba(165, 244, 247, 0.2);">
                    2024 &copy; Powered <i class='uil uil-heart text-danger font-size-12'></i> by
                    <a href="https://medulance.com" target="_blank">Medulance</a>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- end Footer -->