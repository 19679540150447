import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';

@Directive()
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;
    url = 'https://backend.medulance.com/';
    private roleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    role$: Observable<string> = this.roleSubject.asObservable();
    @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();
    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        }
        return this.user;
    }
    getEmitter() {
        return this.fireIsLoggedIn;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(api: string, payload) {
        const headers = new HttpHeaders()
            .set('content-type', 'application/json')
        return this.http.post(this.url + api, payload, { 'headers': headers })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user['status_code'] == 1) {
                    this.fireIsLoggedIn.emit(JSON.stringify(user));
                    // store user details and jwt in cookie
                    this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
                    this.cookieService.setCookie('activeProduct', user['data'].admin_role.product_access[0], 1);
                }
                return user;
            }));
    }
    login1(name: string, data: any, options?: any) {
        const headers = new HttpHeaders()
            .set('content-type', 'application/json')
        return this.http.post(this.url + name, data, { 'headers': headers });
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.cookieService.deleteCookie('activeProduct');
        this.user = null;
    }
    updateRole(role: string) {
        this.roleSubject.next(role);
    }
    getAdminId(): number {
        const user = this.currentUser();
        return user ? user['data'].admin_id : null;
    }

}

