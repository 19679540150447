import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { MENU } from '../../layouts/shared/menu/menu';
import { MenuItem } from 'src/app/layouts/shared/menu/menu.model';
import { CookieService } from '../services/cookie.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivateChild {
    menu: any;
    menuItems = MENU || [];
    activeProduct = 1;
    isPageAccess = false;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private cookie: CookieService,
    ) { }

    canActivate(route: any, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        // this.menuItems = MENU

        if (!!currentUser) {
            this.activeProduct = parseInt(this.cookie.getCookie('activeProduct'));
            this.menuItems.map(item => {
                if (item.link && item.link == state.url && item.productPage.includes(this.activeProduct)) {
                    this.isPageAccess = true;
                    // this.router.navigate(['/']);
                    return;
                }
                if (item.subItems) {
                    item.subItems = item.subItems.filter(subItem => {
                        if (subItem.link) {
                            if (subItem.link == state.url && subItem.productPage.includes(this.activeProduct)) {
                                this.isPageAccess = true;
                            }
                            return subItem.link == state.url
                        }
                    })
                }

            })

            // logged in so return true
            console.log(state.url, this.isPageAccess, state.url != '/');

            // if (!this.isPageAccess && state.url != '/') {
            if (!this.isPageAccess && state.url == '123/') {
                this.cookie.deleteCookie('currentUser');
                this.cookie.deleteCookie('activeProduct');
            } else {
                // alert("returning true")
                // this.router.navigate(['/']);
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        // alert("going y login page")
        // this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['/account/login']);
        return true;

    }
    canActivateChild() {
        // alert('canActivate childs')
        // if (this.router.routerState.snapshot.url != '/account/login?returnUrl=%2F') {
        //     return this.canActivate('', this.router.routerState.snapshot);
        // }
        return true;
    }
}