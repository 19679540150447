import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/auth.service';
import { Notification } from './topbar.model';
import { notificationItems } from './data';
import { ProductAccess } from 'src/app/shared/productaccess';
import { ErsServicesService } from 'src/app/pages/ers/services/ers-services.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireDatabase } from '@angular/fire/database';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @ViewChild('newEventModal', { static: true }) newEventModal: any;
  availableProducts = [];
  activeProduct = '1';
  notificationItems: Notification[];
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };
  callData: any = {};
  newData: any;
  error = '';
  openMobileMenu: boolean;
  console = console;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() ersButtonClicked = new EventEmitter();
  name: string = '';
  cookieService: any;
  cookieValue: any;
  notificationData: any;

  constructor(private router: Router,
    private authService: AuthenticationService,
    private _service: ErsServicesService,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private db: AngularFireDatabase
  ) { }

  ngOnInit() {
    // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;
    this.availableProducts = ProductAccess;
    this.activeProduct = this.cookie.getCookie('activeProduct');

    if (this.authService.currentUser()) {
      const userData = this.authService.currentUser();
      this.onActive(userData['data']['admin_username']);
    }

  }

  getCompanyName(cName) {
    return ['Godrej Health and Wellness', 'Saroj Hospital'].includes(cName) ? '' : 'Medulance';
  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.db.database.goOffline();
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  _fetchNotifications() {
    this.notificationItems = notificationItems;
  }
  onProductChange(event) {
    this.cookie.setCookie('activeProduct', event, 48);
    this.router.navigate(['/']);
    setTimeout(function () {
      window.location.reload();
    }, 100)

    this.activeProduct = event;
  }


  openModal(Content: any) {
    this.modalService.open(Content, { size: 'md', backdrop: 'static', });
    // const databaseRef = this.db.database.ref('notification/calls');
    // console.log(databaseRef)
  }

  dismissModal(reason: string) {
    this.modalService.dismissAll(reason);
  }

  onAccept(reason: string) {
    this.dismissModal(reason);
    if (this.router.url == '/medualert/addbooking') {
      location.reload();
    } else {
      this.router.navigate(['medualert/addbooking']);
    }
  }


  onActive(user) {
    const databaseRef = this.db.database.ref('notification/calls/' + user);
    databaseRef.on('value', (data: any) => {
      if (data.val()) {
        this.notificationData = data.val();

        const user = this.authService.currentUser();
        if (user && !this.notificationData.read && user['data'].admin_username == this.notificationData.caller_agent_id) {
          this.openModal(this.newEventModal);
          localStorage.setItem('callData', JSON.stringify(this.notificationData));
          databaseRef.update({ read: 1 });
        }

      }
    })
  }
}


