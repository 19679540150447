<!-- Topbar Start -->





<div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
    <div class="container-fluid">
        <!-- LOGO -->
        <a routerLink="/" class="navbar-brand mr-0 mr-md-2 logo">
            <span class="logo-lg">
                <img src="assets/images/logo.png" alt="" height="40" />
                <!-- <span class="d-inline h5 ml-1 text-logo ml-2">Medulance</span> -->
            </span>
            <span class="logo-sm">
                <img src="assets/images/logo.png" alt="" height="24">
            </span>
        </a>

        <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
            <li class="">
                <button class="button-menu-mobile open-left disable-btn" (click)="toggleMobileMenu($event)">
                    <i [attr.data-feather]="'menu'" class="menu-icon" appFeatherIcon></i>
                    <i [attr.data-feather]="'x'" class="close-icon" appFeatherIcon></i>
                </button>
            </li>
        </ul>

        <ul class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
            <li class="d-none d-sm-block">
                <div class="app-search">
                    <form>
                        <div class="input-group">
                            <select class="form-control" (change)="onProductChange($event.target.value)">
                                <option *ngFor="let product of availableProducts" value={{product.id}}
                                    [selected]="product.id == activeProduct">
                                    {{ product.name}}

                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </li>

            <li class="dropdown notification-list align-self-center profile-dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle nav-user mr-0" ngbDropdownToggle href="javascript: void(0);"
                    role="button">
                    <div class="media user-profile">
                        <img src="assets/images/users/avatar-7.jpg" alt="user-image"
                            class="rounded-circle align-self-center" />
                        <div class="media-body text-left">
                            <h6 class="pro-user-name ml-2 my-0">
                                <span>{{name}}</span>
                                <span class="pro-user-desc text-muted d-block mt-1">Administrator </span>
                            </h6>
                        </div>
                        <i [attr.data-feather]="'chevron-down'" class="ml-2 align-self-center" appFeatherIcon></i>
                    </div>
                </a>
                <div class="dropdown-menu profile-dropdown-items dropdown-menu-right" ngbDropdownMenu>
                    <a routerLink="/pages-profile" class="dropdown-item notify-item" ngbDropdownItem>
                        <i [attr.data-feather]="'user'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
                        <span>&nbsp;My Account</span>
                    </a>
                    <div class="dropdown-divider"></div>

                    <a href="javascript:void(0);" (click)="logout()" class="dropdown-item notify-item" ngbDropdownItem>
                        <i [attr.data-feather]="'log-out'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
                        <span>&nbsp;Logout</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>

</div>

<!-- end Topbar -->

<ng-template #newEventModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title">You have a new Call !!</h6>
    </div>
    <div class="modal-body" style="text-align: center;">
        <!-- <p>Welcome to Medulance, How may i assist you!</p> -->
        <p>Welcome to {{ getCompanyName(notificationData?.companyName)}} Emergency Helpline for
            {{notificationData?.companyName}}. This is {{notificationData?.caller_agent_id | titlecase}}, What is your
            emergency?</p>
        <span>Client: </span><b>{{notificationData?.companyName}}</b><br>
        <span>Mobile: </span><b>{{notificationData?.incoming_call_number}}</b>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onAccept('accepted')">Ok</button>
    </div>
</ng-template>