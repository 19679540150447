import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Navigation',
        isTitle: true,
        productPage: [1, 2]
    },
    {
        label: 'Dashboard',
        icon: 'home',
        link: '/',
        badge: {
            variant: 'success',
            text: '1',
        },
        productPage: [1, 2],
        pageId: 1
    },
    {
        label: 'Apps',
        isTitle: true,
        productPage: [1]
    },
    {
        label: 'Booking',
        icon: 'file-text',
        subItems: [
            {
                label: 'Booking List',
                link: '/medualert/bookinglist',
                productPage: [1],
                pageId: 2.1
            },
            {
                label: 'Add Booking',
                link: '/medualert/addbooking',
                productPage: [1],
                pageId: 2.2
            },
            {
                label: 'Feedback List',
                link: '/medualert/bookingdetail',
                productPage: [1],
                pageId: 2.3
            },
            {
                label: 'ERS Bookings',
                link: '/ers/bookinglist',
                productPage: [2],
                pageId: 2.3
            },


        ],
        productPage: [1, 2],
        pageId: 2
    },

    {
        label: 'Vendor',
        icon: 'file-text',
        subItems: [
            {
                label: 'Vendor List',
                link: '/vendor/list',
                productPage: [1],
                pageId: 3.1
            },
            {
                label: 'Add Vendor',
                link: '/vendor/add',
                productPage: [1],
                pageId: 3.2
            },
            {
                label: 'AmbulanceList',
                link: '/vendor/ambulance',
                productPage: [1],
                pageId: 3.3
            },
            {
                label: 'Driver List',
                link: '/vendor/driver',
                productPage: [1],
                pageId: 3.4
            },
            {
                label: 'Vendor Map',
                link: '/vendor/map',
                productPage: [1],
                pageId: 3.5
            }
        ],
        productPage: [1],
        pageId: 3
    },
    {
        label: 'Brand',
        icon: 'briefcase',
        subItems: [
            {
                label: 'Brand List',
                link: '/medualert/brandlist',
                productPage: [1],
                pageId: 4.1
            },
            {
                label: 'New Brand',
                link: '/medualert/addbrand',
                productPage: [1],
                pageId: 4.2
            },
        ],
        productPage: [1],
        pageId: 4
    },
    {
        label: 'User',
        icon: 'user',
        subItems: [
            {
                label: 'User List',
                link: '/medualert/userlist',
                productPage: [1],
                pageId: 5.1
            },
            {
                label: 'New User',
                link: '/medualert/adduser',
                productPage: [1],
                pageId: 5.2
            },
        ],
        productPage: [1],
        pageId: 5
    },
    {
        label: 'Admin User',
        icon: 'user',
        subItems: [
            {
                label: 'Admin User List',
                link: '/medualert/adminuserlist',
                productPage: [1],
                pageId: 6.1
            },
            {
                label: 'New Admin',
                link: '/medualert/addadmin',
                productPage: [1],
                pageId: 6.2
            },
        ],
        productPage: [1],
        pageId: 6
    },
    {
        label: 'Enquiry',
        icon: 'file-text',
        subItems: [
            {
                label: 'Enquiry List',
                link: '/medualert/enquiry',
                productPage: [1],
                pageId: 7.1
            },

        ],
        productPage: [1],
        pageId: 7
    },
];
