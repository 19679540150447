import { Component, OnInit, AfterViewInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { activateMenuItems, resetMenuItems } from './utils';
import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { CookieService } from 'src/app/core/services/cookie.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() isCondensed: boolean;
  @Input() mode: string;
  sidebarScrollRef: any;

  menu: any;
  cookieValue: any;
  cookieService: any;
  menuItems1 = [
    {
      label: 'Navigation',
      isTitle: true,
      productPage: [1, 2]
    },
    {
      label: 'Dashboard',
      icon: 'home',
      link: '/',
      badge: {
        variant: 'success',
        text: '1',
      },
      productPage: [1, 2],
      pageId: 1
    },
    {
      label: 'Apps',
      isTitle: true,
      productPage: [1]
    },
    {
      label: 'Booking',
      icon: 'file-text',
      subItems: [
        {
          label: 'Booking List',
          link: '/medualert/bookinglist',
          productPage: [1],
          pageId: 2.1
        },
        {
          label: 'Add Booking',
          link: '/medualert/addbooking',
          productPage: [1],
          pageId: 2.2
        },
        // {
        //     label: 'Booking Detail',
        //     link: '/medualert/bookingdetail',
        //     productPage: [1]
        // },
        {
          label: 'ERS Bookings',
          link: '/ers/bookinglist',
          productPage: [2],
          pageId: 2.3
        },


      ],
      productPage: [1, 2],
      pageId: 2
    },

    {
      label: 'Vendor',
      icon: 'file-text',
      subItems: [
        {
          label: 'Vendor List',
          link: '/vendor/list',
          productPage: [1],
          pageId: 3.1
        },
        {
          label: 'Add Vendor',
          link: '/vendor/add',
          productPage: [1],
          pageId: 3.2
        },
        {
          label: 'AmbulanceList',
          link: '/vendor/ambulance',
          productPage: [1],
          pageId: 3.3
        },
        {
          label: 'Driver List',
          link: '/vendor/driver',
          productPage: [1],
          pageId: 3.4
        },
        {
          label: 'Vendor Map',
          link: '/vendor/map',
          productPage: [1],
          pageId: 3.5
        }
      ],
      productPage: [1],
      pageId: 3
    },
    {
      label: 'Brand',
      icon: 'briefcase',
      subItems: [
        {
          label: 'Brand List',
          link: '/medualert/brandlist',
          productPage: [1],
          pageId: 4.1
        },
        {
          label: 'New Brand',
          link: '/medualert/addbrand',
          productPage: [1],
          pageId: 4.2
        },
      ],
      productPage: [1],
      pageId: 4
    },
    {
      label: 'User',
      icon: 'user',
      subItems: [
        {
          label: 'User List',
          link: '/medualert/userlist',
          productPage: [1],
          pageId: 5.1
        },
        {
          label: 'New User',
          link: '/medualert/adduser',
          productPage: [1],
          pageId: 5.2
        },
      ],
      productPage: [1],
      pageId: 5
    },
    {
      label: 'Admin User',
      icon: 'user',
      subItems: [
        {
          label: 'Admin User List',
          link: '/medualert/adminuserlist',
          productPage: [1],
          pageId: 6.1
        },
        {
          label: 'New Admin',
          link: '/medualert/addadmin',
          productPage: [1],
          pageId: 6.2
        },
      ],
      productPage: [1],
      pageId: 6
    },
    {
      label: 'Enquiry',
      icon: 'file-text',
      subItems: [
        {
          label: 'Enquiry List',
          link: '/medualert/enquiry',
          productPage: [1],
          pageId: 7.1
        },

      ],
      productPage: [1],
      pageId: 7
    },
  ];
  availableProducts = [];
  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(router: Router, private cookieservice: CookieService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }

  ngOnInit() {
    this.initialize();
  }

  ngAfterViewInit() {
    // activate menu item
    this._initMenu();
  }

  /**
   * On prop change, look for layout settings
   */
  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this._initMenu();
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  /**
   * Initizes metis menu
   */
  _initMenu() {
    if (this.mode === 'horizontal') {
      const menuRef = new MetisMenu(this.sideMenu.nativeElement).on('shown.metisMenu', (event) => {
        window.addEventListener('click', function menuClick(e) {
          if (!event.target.contains(e.target)) {
            menuRef.hide(event.detail.shownElement);
            window.removeEventListener('click', menuClick);
          }
        });
      });
    } else {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
    }
    this._activateMenuDropdown();
  }


  /**
   * Activate the parent dropdown
   * TODO: This is hard-coded check - change to some common way
   */
  _activateMenuDropdown() {
    const activeClass = this.mode === 'horizontal' ? 'active' : 'mm-active';
    const dropdownActiveClass = this.mode === 'horizontal' ? 'active' : 'mm-show';

    resetMenuItems(activeClass, dropdownActiveClass);
    if (this.mode === 'horizontal') {
      resetMenuItems('mm-active', 'mm-show');
    }
    activateMenuItems(activeClass, dropdownActiveClass);
  }

  /**
   * Initilize
   */
  initialize(): void {
    // console.log(this.menuItems1);

    const activeProduct = parseInt(this.cookieservice.getCookie('activeProduct'));
    const activeUser = JSON.parse(this.cookieservice.getCookie('currentUser')) || '{}';
    const pageAccesses = activeUser.data.admin_role.page_access;
    console.log(pageAccesses);
    this.menuItems1.map(item => {
      if (item.subItems) {
        item.subItems = item.subItems.filter(subItem => {
          if (subItem.productPage) {
            return subItem.productPage.includes(activeProduct) && pageAccesses.includes(subItem.pageId)
          }
        })
      }
    });
    this.menuItems1 = this.menuItems1.filter(mItem => pageAccesses.includes(mItem.pageId) && mItem.productPage.includes(activeProduct))


  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Hides the menubar
   */
  hideMenu() {
    document.body.classList.remove('sidebar-enable');
  }
}
