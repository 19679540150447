
const ProductAccess = [
    {
        id: 1,
        name: 'Medualert',


    }, {
        id: 2,
        name: 'ERS',


    }
];
export { ProductAccess };