import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'src/app/core/services/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class ErsServicesService {
  public readonly _URL: string = 'https://ers.medulance.com/api/';
  private token: string = '';
  activeProduct: any;
  constructor(private http: HttpClient, private cookieService: CookieService) {

  }
  public getlist(name: string, options?: any) {
    let payload = {};
    let user = JSON.parse(this.cookieService.getCookie('currentUser'));
    this.token = 'bearer ' + user['token'];
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      //.set('Access-Control-Allow-Origin', '*')
      .set('Authorization', this.token);
    return this.http.post(this._URL + name, payload, { 'headers': headers });
  }
  public post(name: string, data: any, options?: any) {
    let user = JSON.parse(this.cookieService.getCookie('currentUser'));
    this.token = 'bearer ' + user['token'];
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', this.token);
    return this.http.post(this._URL + name, data, { 'headers': headers });
  }


}
