<div class="row page-title">
  <div class="col-md-12">
    <nav aria-label="breadcrumb" class="float-right mt-1">
      <ol class="breadcrumb">
        <ng-container *ngFor="let item of breadcrumbItems">
          <li class="breadcrumb-item" *ngIf="!item.active">
            <a href="{{item.path}}">{{ item.label }}</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="item.active">{{ item.label }}</li>
        </ng-container>
      </ol>
    </nav>

    <h4 class="mb-1 mt-0">{{ title }}</h4>
  </div>
</div>