// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  GOOGLE_MAP_KEY: "AIzaSyDkpAuKvwaEbxDhzlt_qL2u_MDgrDwZYC0",
  ambulanceType: ['', 'ALS', 'BLS', 'Patient Transport', 'Mortuary(PT)'],
  serviceStatus:
    [
      { name: "Pending", value: 0 },
      { name: "IN Process", value: 1 },
      { name: "Booked", value: 2 },
      { name: "En-route", value: 3 },
      { name: "Completed", value: 4 },
      { name: "NotFulfilled", value: 5 },
      { name: "Cancelled", value: 9 },
    ],

  chiefComplaint:
    [
      { name: "Accident/  Road accident", value: 1, type: 1 },
      { name: "Allergic reaction", value: 2, type: 2 },
      { name: "Animal bites/ Insect bites/ Snake bite", value: 3, type: 1 },
      { name: "Asthama /Breathing Difficulty", value: 4, type: 1 },
      { name: "High Blood Pressure", value: 5, type: 1 },
      { name: "Burns", value: 6, type: 1 },
      { name: "Chest pain", value: 7, type: 1 },
      { name: "Choking", value: 8, type: 1 },
      { name: "Delivery and Gyne problems", value: 9, type: 1 },
      { name: "Diabetes / Hypoglycemia", value: 10, type: 1 },
      { name: "Drowning", value: 11, type: 1 },
      { name: "Drug Over dose /Alcohalism", value: 12, type: 1 },
      { name: "Electric Shock", value: 13, type: 1 },
      { name: "Epileptic seizure", value: 14, type: 1 },
      { name: "Fall From Height", value: 15, type: 1 },
      { name: "Heat Stroke", value: 16, type: 1 },
      { name: "High Grade fever", value: 17, type: 1 },
      { name: "Paralysis ", value: 18, type: 1 },
      { name: "Poisoning", value: 19, type: 1 },
      { name: "Psychiatric Problem", value: 20, type: 1 },
      { name: "Spine problem ", value: 21, type: 1 },
      { name: "Suicide", value: 22, type: 1 },
      { name: "Unconscious", value: 23, type: 1 },
      { name: "Vomiting/ Dehydration", value: 24, type: 1 },
      { name: "Scheduled call ", value: 26, type: 2 },
      { name: "Airport Pickup ", value: 27, type: 2 },
      { name: "Mortuary Van ", value: 28, type: 2 },
      { name: "Dialysis", value: 29, type: 2 },
      { name: "Regular check ", value: 30, type: 2 },
      { name: "Post Operative checkup ", value: 31, type: 2 },
      { name: "Patient Can not walk ", value: 32, type: 2 },
      { name: "Inter hospital transfer ", value: 33, type: 2 },
      { name: "Enquiry ", value: 34, type: 3 },
      { name: "Other ", value: '', type: '' },
    ],

  case: [
    { id: 1, name: 'emergency' },
    { id: 2, name: 'nonemergency' }
  ],

  firebaseConfig: {
    apiKey: '"AIzaSyBrUaRrLRldVL6idcaCNCvzz1dqCpRUj0U"',
    authDomain: 'cats-e97d6.firebaseapp.com',
    databaseURL: 'https://cats-e97d6-e7fce.firebaseio.com',
    projectId: 'cats-e97d6',
    storageBucket: 'cats-e97d6.appspot.com',
    messagingSenderId: '348669827980',
    appId: '1:348669827980:web:7a099484e82aab43e254a9',
    measurementId: "G-WG08G8358D"
  },
  cancellationReasons: [
    { name: "Ambulance Enquiry", value: 1 },
    { name: "Price is High", value: 2 },
    { name: "Delay In Ambulance Dispatch", value: 3 },
    { name: "Patient is no more", value: 4 },
    { name: "Previous Experience was not Good", value: 5 },
    { name: "Customer Cancelled on Helpline ( No Reason)", value: 6 },
    { name: "Did Not Pick Follow Up Call (Dispatch Team)", value: 7 },
    { name: "Not Waited for Ambulance Dispatch TAT", value: 8 },
    { name: "Customer denied to wait for 15-20 minutes", value: 9 },
    { name: "Customer not picked up the Follow Up(Dispatch ) Call", value: 10 },
    { name: "Delay In Ambulance Dispatch", value: 11 },
    { name: "Taken Services from Another Partner", value: 12 },
    { name: "Enquiry for Booked Case", value: 13 },
    { name: "Customer Disconnected the call", value: 14 },
    { name: "Not Responding on Follow Up Call", value: 15 },
    { name: "Enquired for Other Product", value: 16 },
    { name: "Need Free Ambulance Services", value: 17 },
    { name: "Arranged By Himself", value: 18 },
    { name: "Not Interested", value: 19 },
    { name: "Others", value: 20 },
    { name: "Unable to confirm the address", value: 21 },
    { name: "Test Call", value: 22 },
    { name: "Language Barrier", value: 23 },
    { name: "Customer called in by mistake", value: 24 },
    { name: "Vendor /Tie Up Call", value: 25 },
    { name: "Follow Up Call", value: 26 },
    { name: "Service (BLS, ALS, PT, Mortuary) Not Available", value: 27 },
  ],

  OLA_apiKey: 'cBkmzKLVE30bu03Q5kj9atA6nyLSarOKwMNS6F7h',  // Replace with your Ola Maps API Key
  OLA_baseUrl: 'https://api.olamaps.io/places/v1',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
